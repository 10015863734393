import kawoszeh from "./items/kawoszeh.otf";
import kawoszehOtf from "./items/kawoszeh.otf";

import JostNormal from "./items/Jost-400-Book.ttf";
import JostNormalOtf from "./items/Jost-400-Book.otf";

export default {
  kawoszeh,
  kawoszehOtf,
  JostNormal,
  JostNormalOtf,
};
